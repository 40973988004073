.skills-title {
    text-align: center;
    height: 40vh;
    margin-bottom: 50px;
    color: white;
    background-color: #ffffff;
    background-image: linear-gradient(35deg, lightseagreen, #233e8b);
}

.skill-area {
    align-items: center;
    background-color: whitesmoke;
    box-shadow: 5px 5px 5px grey;
    margin-bottom: 70px;
    padding-bottom: 20px;
}

.nucamp-badge {
    width: 100px;
    border: 1px solid purple;
}

.certificate {
    width: 280px;
    border: 1px solid grey;
}

.icons {
    width: 150px;
    height: 150px;
}

.carousel-images {
    height: 40vh;
    width: 100%;
}

@media (max-width: 768px) {
    .skill-area {
        width: 99%;
        box-shadow: 0 0 12px grey;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .certificate {
        margin-bottom: 30px;
    }
    .carousel-images {
        height: 20vh;
    }
}
