.resume {
    min-height: 100vh;
    margin-bottom: 50px;
}

.resume-title {
    text-align: center;
    height: 40vh;
    margin-bottom: 50px;
    color: white;
    background-color: #ffffff;
    background-image: linear-gradient(35deg, lightseagreen, #233e8b);
}

.resume-column {
    margin: auto;
    padding: 10px;
    animation-duration: 1s;
    animation-name: border;
    animation-fill-mode: forwards;
}

@keyframes border {
    0% {
        box-shadow: none;
    }
    100% {
        box-shadow: 0px 0px 4px grey;
    }
}

.resume-item {
    width: 100%;
    margin: auto;
}
