@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
body {
  margin: 0;
  font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none !important;
    -webkit-text-emphasis-color: inherit;
            text-emphasis-color: inherit;
}

.link {
    width: 8vw;
    font-size: large;
    color: inherit;
}

.link:hover {
    font-weight: bold;
    border-bottom: 3px solid #233e8b;
    color: inherit;
}

.link-active {
    font-weight: bold;
    border-bottom: 3px solid #233e8b;
}

@media(max-width: 768px) {
    .link {
        width: 30vw;
        -webkit-align-self: center;
                align-self: center;
        padding-top: 20px;
    }
}

.home {
    height: 100vh;
    position: relative;
}

.home::after {
    content:'';
    background-image: url(/static/media/wall-off-center-portrait.31984d6f.JPG);
    background-size: cover;
    position: absolute;
    top:0px;
    left: 0px;
    width:100%;
    height:100%;
    z-index:-1;
    opacity: 0.9;
}

.message {
    -webkit-align-self: center;
            align-self: center;
}

@media(max-width: 768px) {
    .home::after {
        background-image: url(/static/media/wall-off-center-portrait-cropped.20a03be3.jpg);
    }
    .message {
        -webkit-align-self: flex-start;
                align-self: flex-start;
    }
    .title {
        font-size: 28px;
        margin-top: 8vh;
        margin-bottom: 10vh;
    }
    .text {
        font-size: 16px;
    }
}

.about {
    text-align: left;
}

.about-title {
    text-align: center;
    height: 40vh;
    color: white;
    background-color: #ffffff;
    background-image: linear-gradient(35deg, lightseagreen, #233e8b);
}

.about-section {
    margin-top: 50px;
}

.about p {
    text-indent: 50px;
}

.about-images {
    width: 100%;
    height: 30vh;
    margin-top: 50px;
    margin-bottom: 50px;
    object-fit: cover;
    border-radius: 10px;
}

.code-backdrop-img {
    object-position: top;
    opacity: 1;
    transition: 1s ease-in-out;
}

.code-backdrop-img:hover {
    opacity: 0.1;
    object-position: bottom;
}

.html-cover-img {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: .5s ease-in-out;
}

.html-cover-img:hover {
    cursor: url(/static/media/dragon-cursor.aacecc08.png), auto;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.acu-banner-img {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    transition: 1s ease-in-out;
}

.acu-banner-img:hover {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}

.platform-images {
    background-image: url(/static/media/khan-academy.b0e86626.png);
    background-size: cover;
    background-position: center;
    transition: 1s ease-in-out;
}

.platform-images:hover {
    background-image: url(/static/media/code-org.f93e2e75.png);
    background-size: cover;
    background-position-y: 45%;
}

.teacher-certificate-img {
    object-fit: contain;
    border: 2px solid grey;
    background-color: #fff;
    -webkit-filter: grayscale(0) blur(0);
            filter: grayscale(0) blur(0);
    transition: .3s ease-in-out;
}

.teacher-certificate-img:hover {
    -webkit-filter: grayscale(100%) blur(2px);
            filter: grayscale(100%) blur(2px);
}

.edu-schedule-img {
    object-position: top;
    box-shadow: 5px 5px 5px grey;
    transition: 1s ease-in-out;
}

.edu-schedule-img:hover {
    box-shadow: 30px 30px 3px lightseagreen;
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px);
}

.react-banner-img {
    -webkit-filter: invert(0%);
            filter: invert(0%);
    transition: 2s ease-in-out;
}

.react-banner-img:hover {
    -webkit-filter: invert(100%);
            filter: invert(100%);
}

.nucamp-img {
    border: 2px solid grey;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    transition: .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.nucamp-img:hover {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
}

.skills-title {
    text-align: center;
    height: 40vh;
    margin-bottom: 50px;
    color: white;
    background-color: #ffffff;
    background-image: linear-gradient(35deg, lightseagreen, #233e8b);
}

.skill-area {
    -webkit-align-items: center;
            align-items: center;
    background-color: whitesmoke;
    box-shadow: 5px 5px 5px grey;
    margin-bottom: 70px;
    padding-bottom: 20px;
}

.nucamp-badge {
    width: 100px;
    border: 1px solid purple;
}

.certificate {
    width: 280px;
    border: 1px solid grey;
}

.icons {
    width: 150px;
    height: 150px;
}

.carousel-images {
    height: 40vh;
    width: 100%;
}

@media (max-width: 768px) {
    .skill-area {
        width: 99%;
        box-shadow: 0 0 12px grey;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .certificate {
        margin-bottom: 30px;
    }
    .carousel-images {
        height: 20vh;
    }
}

.projects-title {
    text-align: center;
    height: 40vh;
    margin-bottom: 50px;
    color: white;
    background-color: #ffffff;
    background-image: linear-gradient(35deg, lightseagreen, #233e8b);
}

.projects-header {
    text-align: left;
    padding: 10px;
    background-color: rgb(238, 235, 235);
    border-left: 4px solid #233e8b;
}

.projects-link, .projects-link:hover {
    text-decoration: none;
    color: black !important;
}

.projects-images {
    height: 30vh;

}

.projects-card {
    box-shadow: none;
    color: inherit;
    transition: 0.2s ease-in-out;
}

.projects-card:hover {
    -webkit-filter: opacity(0.7);
            filter: opacity(0.7);
    box-shadow: 5px 5px 5px grey;
}

.resume {
    min-height: 100vh;
    margin-bottom: 50px;
}

.resume-title {
    text-align: center;
    height: 40vh;
    margin-bottom: 50px;
    color: white;
    background-color: #ffffff;
    background-image: linear-gradient(35deg, lightseagreen, #233e8b);
}

.resume-column {
    margin: auto;
    padding: 10px;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-name: border;
            animation-name: border;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes border {
    0% {
        box-shadow: none;
    }
    100% {
        box-shadow: 0px 0px 4px grey;
    }
}

@keyframes border {
    0% {
        box-shadow: none;
    }
    100% {
        box-shadow: 0px 0px 4px grey;
    }
}

.resume-item {
    width: 100%;
    margin: auto;
}

.contact {
    height: 100vh;
}

.contact-title {
    text-align: center;
    height: 40vh;
    margin-bottom: 50px;
    color: white;
    background-color: #ffffff;
    background-image: linear-gradient(35deg, lightseagreen, #233e8b);
}

.contact-item {
    padding-left: 35% !important;
}

@media (max-width: 768px) {
    .contact-item {
        padding-left: 25% !important;
    }
}

.footer {
    height: 20vh;
    color: white;
    background-color: #233e8b;
}

.footer a {
    color: white;
}

.social-icons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.App {
  text-align: center;
}

.main-content {
  height: 90vh;
  width: 100%;
  overflow: scroll;
}

