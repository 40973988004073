a {
    text-decoration: none !important;
    text-emphasis-color: inherit;
}

.link {
    width: 8vw;
    font-size: large;
    color: inherit;
}

.link:hover {
    font-weight: bold;
    border-bottom: 3px solid #233e8b;
    color: inherit;
}

.link-active {
    font-weight: bold;
    border-bottom: 3px solid #233e8b;
}

@media(max-width: 768px) {
    .link {
        width: 30vw;
        align-self: center;
        padding-top: 20px;
    }
}
