.contact {
    height: 100vh;
}

.contact-title {
    text-align: center;
    height: 40vh;
    margin-bottom: 50px;
    color: white;
    background-color: #ffffff;
    background-image: linear-gradient(35deg, lightseagreen, #233e8b);
}

.contact-item {
    padding-left: 35% !important;
}

@media (max-width: 768px) {
    .contact-item {
        padding-left: 25% !important;
    }
}
