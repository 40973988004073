.home {
    height: 100vh;
    position: relative;
}

.home::after {
    content:'';
    background-image: url('../images/wall-off-center-portrait.JPG');
    background-size: cover;
    position: absolute;
    top:0px;
    left: 0px;
    width:100%;
    height:100%;
    z-index:-1;
    opacity: 0.9;
}

.message {
    align-self: center;
}

@media(max-width: 768px) {
    .home::after {
        background-image: url("../images/wall-off-center-portrait-cropped.jpg");
    }
    .message {
        align-self: flex-start;
    }
    .title {
        font-size: 28px;
        margin-top: 8vh;
        margin-bottom: 10vh;
    }
    .text {
        font-size: 16px;
    }
}
