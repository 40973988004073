.projects-title {
    text-align: center;
    height: 40vh;
    margin-bottom: 50px;
    color: white;
    background-color: #ffffff;
    background-image: linear-gradient(35deg, lightseagreen, #233e8b);
}

.projects-header {
    text-align: left;
    padding: 10px;
    background-color: rgb(238, 235, 235);
    border-left: 4px solid #233e8b;
}

.projects-link, .projects-link:hover {
    text-decoration: none;
    color: black !important;
}

.projects-images {
    height: 30vh;

}

.projects-card {
    box-shadow: none;
    color: inherit;
    transition: 0.2s ease-in-out;
}

.projects-card:hover {
    filter: opacity(0.7);
    box-shadow: 5px 5px 5px grey;
}
