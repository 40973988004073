.about {
    text-align: left;
}

.about-title {
    text-align: center;
    height: 40vh;
    color: white;
    background-color: #ffffff;
    background-image: linear-gradient(35deg, lightseagreen, #233e8b);
}

.about-section {
    margin-top: 50px;
}

.about p {
    text-indent: 50px;
}

.about-images {
    width: 100%;
    height: 30vh;
    margin-top: 50px;
    margin-bottom: 50px;
    object-fit: cover;
    border-radius: 10px;
}

.code-backdrop-img {
    object-position: top;
    opacity: 1;
    transition: 1s ease-in-out;
}

.code-backdrop-img:hover {
    opacity: 0.1;
    object-position: bottom;
}

.html-cover-img {
    transform: scale(1);
    transition: .5s ease-in-out;
}

.html-cover-img:hover {
    cursor: url('../images//dragon-cursor.png'), auto;
    transform: scale(1.1);
}

.acu-banner-img {
    filter: blur(1px);
    filter: grayscale(100%);
    transition: 1s ease-in-out;
}

.acu-banner-img:hover {
    filter: grayscale(0);
}

.platform-images {
    background-image: url('../images/khan-academy.png');
    background-size: cover;
    background-position: center;
    transition: 1s ease-in-out;
}

.platform-images:hover {
    background-image: url('../images/code-org.png');
    background-size: cover;
    background-position-y: 45%;
}

.teacher-certificate-img {
    object-fit: contain;
    border: 2px solid grey;
    background-color: #fff;
    filter: grayscale(0) blur(0);
    transition: .3s ease-in-out;
}

.teacher-certificate-img:hover {
    filter: grayscale(100%) blur(2px);
}

.edu-schedule-img {
    object-position: top;
    box-shadow: 5px 5px 5px grey;
    transition: 1s ease-in-out;
}

.edu-schedule-img:hover {
    box-shadow: 30px 30px 3px lightseagreen;
    transform: translate(-10px, -10px);
}

.react-banner-img {
    filter: invert(0%);
    transition: 2s ease-in-out;
}

.react-banner-img:hover {
    filter: invert(100%);
}

.nucamp-img {
    border: 2px solid grey;
    transform: rotate(0);
    transition: .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.nucamp-img:hover {
    transform: rotate(6deg);
}
