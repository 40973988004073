.footer {
    height: 20vh;
    color: white;
    background-color: #233e8b;
}

.footer a {
    color: white;
}

.social-icons {
    display: flex;
    justify-content: center;
}
